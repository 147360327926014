/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SharedEnumsSocialMedia = {
    Facebook: 'Facebook',
    Instagram: 'Instagram',
    LinkedIn: 'LinkedIn',
    Twitter: 'Twitter',
    Pinterest: 'Pinterest',
    TikTok: 'TikTok',
    YouTube: 'YouTube'
} as const;

export type SharedEnumsSocialMedia = typeof SharedEnumsSocialMedia[keyof typeof SharedEnumsSocialMedia];



