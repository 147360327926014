<template>
  <div :class="mergedClasses">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { computed } from 'vue';

const props = defineProps({
  class: {
    type: String,
    default: '',
  },
});


const defaultClasses = 'max-w-full rounded-xl bg-white shadow shadow-gray-400 py-5 px-6';

const mergedClasses = computed(() => {
  return twMerge(defaultClasses, props.class);
});
</script>
