export enum ACCOUNT_ROUTES {
  LOGIN = 'routesLogin',
  LOGIN_AS = 'routesLoginAs',
  REGISTER = 'routesRegister',
  SOCIAL_AUTH_CALLBACK = 'routesSocialAuthCallback',
  FORGOTTEN_PASSWORD = 'routesForgottenPassword',
  RESET_PASSWORD_CALLBACK = 'routesResetPasswordCallback',
  CHANGE_EMAIL_CALLBACK = 'routesChangeEmailCallback',
  ADD_ESHOP_MANAGER_CALLBACK = 'routesAddEshopManagerCallback',
  SETTINGS = 'routesSettings',
  LIST_OF_ESHOPS = 'routesListOfEshops',
  LOGOUT = 'routesLogout',
}
