/* tslint:disable */
/* eslint-disable */
/**
 * Tanganica API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SharedEnumsContentGoal = {
    IncreaseSales: 'IncreaseSales',
    BoostTraffic: 'BoostTraffic',
    BrandAwareness: 'BrandAwareness',
    Engagement: 'Engagement',
    LeadGeneration: 'LeadGeneration',
    CustomerLoyalty: 'CustomerLoyalty',
    ProductLaunch: 'ProductLaunch',
    EventPromotion: 'EventPromotion',
    Education: 'Education',
    CustomerFeedback: 'CustomerFeedback',
    CommunityBuilding: 'CommunityBuilding',
    Promotions: 'Promotions',
    SocialProof: 'SocialProof',
    CrisisManagement: 'CrisisManagement',
    ReputationManagement: 'ReputationManagement'
} as const;

export type SharedEnumsContentGoal = typeof SharedEnumsContentGoal[keyof typeof SharedEnumsContentGoal];



