import { RouteRecordRaw } from "vue-router";
import { AFFILIATE_PROGRAM_ROUTES } from "../enums/RoutesEnum";
import FullPageLayout from "@/oldLayout/FullPageLayout.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: 'affiliate-program',
        meta: {
            module: 'affiliateProgram',
            layout: FullPageLayout,
            quest: false,
            client: true,
        },
        children: [
            {
                path: '',
                name: AFFILIATE_PROGRAM_ROUTES.INDEX,
                component: () => import('@/modules/affiliateProgram/pages/AffiliateProgram.vue'),
            },
        ],
    }
];

export default routes;