import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faXTwitter, faInstagram, faApple, faGoogle, faSquareFacebook, faSquareInstagram, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCircleCheck as farCircleCheck, faXmarkCircle as farXmarkCircle, faCircleQuestion as farCircleQuestion, faThumbsUp as farThumbsUp, faComment as farComment, faPaperPlane as farPaperPlane, faUser as farUser } from '@fortawesome/free-regular-svg-icons';
import {
  faSearch,
  faChartBar,
  faStore,
  faBox,
  faExchangeAlt,
  faGraduationCap,
  faChartPie,
  faChartLine,
  faImage,
  faAd,
  faDollarSign,
  faPercentage,
  faBoxOpen,
  faTrafficLight,
  faGears,
  faArrowsRotate,
  faMoneyBillTransfer,
  faDownload,
  faAngleRight,
  faTriangleExclamation,
  faCreditCard,
  faUserSecret,
  faCheckCircle,
  faHome,
  faBars,
  faCircleUser,
  faRightFromBracket,
  faCircleDot,
  faTableColumns,
  faWandSparkles,
  faChevronDown,
  faChevronUp,
  faCircleArrowLeft,
  faHouse,
  faArrowLeft,
  faArrowRight,
  faQuestionCircle,
  faUserCircle,
  faCircleCheck,
  faArrowsLeftRight,
  faArrowUpFromBracket,
  faArrowPointer,
  faGear,
  faCheck,
  faExclamationCircle,
  faGamepad,
  faXmark,
  faPencil,
  faTrashCan,
  faRotateLeft,
  faPlus,
  faSpinner,
  faEye,
  faShoppingCart,
  faShareNodes,
  faShare,
  faCircle,
  faEnvelope,
  faGlobe,
  faKey,
  faPhone,
  faSackDollar,
  faPalette,
  faBell,
  faExclamation,
  faList,
  faDeleteLeft,
  faClock,
  faPause,
  faSquareMinus,
  faEllipsisVertical,
  faChevronLeft,
  faChevronRight,
  faUserGroup,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faSearch,
  faChartBar,
  faStore,
  faBox,
  faExchangeAlt,
  faGraduationCap,
  faChartPie,
  faChartLine,
  faPercentage,
  faBoxOpen,
  faTrafficLight,
  faImage,
  faAd,
  faDollarSign,
  farPaperPlane,
  faGears,
  faArrowsRotate,
  faMoneyBillTransfer,
  faDownload,
  faAngleRight,
  faTriangleExclamation,
  farThumbsUp,
  farComment,
  faShare,
  faShareNodes,
  faEye,
  faShoppingCart,
  farCircleQuestion,
  faSpinner,
  faPlus,
  faRotateLeft,
  faPencil,
  faTrashCan,
  faFacebookF,
  faXTwitter,
  faInstagram,
  faApple,
  faGoogle,
  faSquareFacebook,
  faSquareInstagram,
  faSquareXTwitter,
  faUserSecret,
  faCheckCircle,
  faHome,
  faBars,
  faCircleDot,
  faCircleUser,
  faRightFromBracket,
  faTableColumns,
  faWandSparkles,
  faChevronDown,
  faChevronUp,
  faCircleArrowLeft,
  faHouse,
  faArrowLeft,
  faArrowRight,
  faQuestionCircle,
  faUserCircle,
  faCreditCard,
  faCircleCheck,
  faGamepad,
  faArrowsLeftRight,
  faArrowUpFromBracket,
  farCircleCheck,
  farXmarkCircle,
  faArrowPointer,
  faGear,
  faCheck,
  faExclamationCircle,
  faXmark,
  faCircle,
  faEnvelope,
  faGlobe,
  faKey,
  faPhone,
  faSackDollar,
  faPalette,
  faBell,
  faUser,
  faExclamation,
  faList,
  faDeleteLeft,
  faClock,
  faSquareMinus,
  faPause,
  faEllipsisVertical,
  faChevronLeft,
  faChevronRight,
  faUserGroup,
  farUser
);

export default FontAwesomeIcon;
