import { RouteRecordRaw } from "vue-router";
import ClientLayout from "@/layout/ClientLayout.vue";
import { PRODUCT_ROUTES } from "../enums/RoutesEnum";

const routes: Array<RouteRecordRaw> = [
    {
        path: 'products',
        meta: {
            module: 'products',
            layout: ClientLayout,
            quest: false,
            client: true,
        },
        name: PRODUCT_ROUTES.INDEX,
        redirect: { name: PRODUCT_ROUTES.PRODUCT_STATUS_PAGE },
        component: () => import('@/modules/products/pages/index.vue'),
        children: [
            {
                path: 'product-status',
                name: PRODUCT_ROUTES.PRODUCT_STATUS_PAGE,
                component: () => import('@/modules/products/pages/productStatus.vue'),
            },
            {
                path: 'product-delete',
                name: PRODUCT_ROUTES.PRODUCT_DELETE_PAGE,
                component: () => import('@/modules/products/pages/productDelete.vue'),
            },
            {
                path: 'category-delete', 
                name: PRODUCT_ROUTES.CATEGORY_DELETE_PAGE,
                component: () => import('@/modules/products/pages/categoryDelete.vue'),
            },
        ],
    },
];

export default routes;