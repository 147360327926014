import { defineStore } from 'pinia';
import { useAuthStore } from '@/modules/shared/stores/auth';
import { useUserStore } from '@/modules/shared/stores/user';
import { api } from '@/services/api';
import { ClientEshopListEshopInfo } from '../../generated/api';

interface State {
  selectedEshop: ClientEshopListEshopInfo;
  managedEshops: ClientEshopListEshopInfo[];
}

export const useEshopsStore = defineStore('eshops', {
  state: (): State => ({
    selectedEshop: {},
    managedEshops: [],
  }),
  getters: {
    getSelectedEshop: (state) => {
      if (Object.keys(state.selectedEshop).length === 0) {
        return null;
      } else {
        return state.selectedEshop;
      }
    },
  },
  actions: {
    setManagedEshops(eshops) {
      this.managedEshops = eshops;
    },
    async setSelectedEshop(eshop: ClientEshopListEshopInfo) {
      const authStore = useAuthStore();
      const userStore = useUserStore();

      try {
        const { data: getEshopAccessTokenResponse } = await api.authAuthGetEshopAccessToken(
          {
            eshopId: eshop?.id || '',
          },
          {
            headers: {
              Authorization: `Bearer ${authStore.refreshToken}`,
            },
            metadata: {
              skipAccessTokenCheck: true,
            },
          }
        );

        // Update the auth store
        if (getEshopAccessTokenResponse.accessToken) {
          authStore.setEshopAccessToken(getEshopAccessTokenResponse.accessToken);
          this.selectedEshop = eshop;
          await userStore.getPermissions();
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    async updateEshopAfterLoginFromAdmin(eshop) {
      const userStore = useUserStore();
      this.selectedEshop = eshop;
      await userStore.getPermissions();
      this.managedEshops.push(eshop);
    },
    async updateOrCreateEshop(eshop: ClientEshopListEshopInfo) {
      const transformedEshop = eshop;
      const eshopIndex = this.managedEshops.findIndex((eshop) => eshop.id === transformedEshop.id);

      // Update existing eshop and set it as selected
      if (eshopIndex !== -1) {
        this.managedEshops[eshopIndex] = transformedEshop;
        this.selectedEshop = transformedEshop;
        return;
      }

      // Create new eshop and set it as selected
      this.managedEshops.push(transformedEshop);
      this.selectedEshop = transformedEshop;
    },
    resetEshops() {
      this.$reset();
    },
  },
  persist: true,
});
