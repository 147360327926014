<template>
  <component v-if="stepComponent" :is="stepComponent" :module="props.module" @edit="onEdit" :isDirty="isDirty" />
  <Dialog v-model:visible="confirmLeaveDialog" modal :draggable="false">
    <template #header><span> </span></template>
    <h2 class="text-center">{{ $t('campaigns.confirmLeaveDialogTitle') }}</h2>
    <template #footer>
      <div class="mx-auto flex flex-col items-center justify-between gap-x-2 gap-y-4 md:w-[25rem] md:flex-row md:gap-y-0">
        <Button @click="onCancel()" :label="$t('campaigns.cancel')"></Button>
        <Button @click="onConfirm()" :label="$t('campaigns.confirm')"></Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { PropType, shallowRef, watch, ref } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import { ModulesEnum } from '@/modules/shared/enums/ModulesEnum';
import { SharedEnumsMarketingChannel, SharedEnumsMarketingFormat } from '../../../../generated/api';

const props = defineProps({
  channel: {
    type: String as PropType<SharedEnumsMarketingChannel>,
    required: true,
  },
  format: {
    type: String as PropType<SharedEnumsMarketingFormat>,
    required: true,
  },
  module: {
    type: String as PropType<ModulesEnum>,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const stepComponent = shallowRef<any>(null);

const loadComponent = async () => {
  let customChannelName = ''; // TODO fix when BE fixes the name

  if (props.channel === SharedEnumsMarketingChannel.Facebook) {
    customChannelName = 'Meta';
  } else {
    customChannelName = props.channel;
  }

  let customFormatName = ''; // TODO fix when BE fixes the name

  if (props.format === SharedEnumsMarketingFormat.BingPrx) {
    customFormatName = 'PRX';
  } else if (props.format === SharedEnumsMarketingFormat.BingDsa) {
    customFormatName = 'DSA';
  } else {
    customFormatName = props.format;
  }

  console.log(customChannelName, customFormatName);

  try {
    const module = await import(`@/modules/campaigns/pages/${customChannelName?.toLowerCase()}/${customFormatName?.toUpperCase()}/${props.step?.charAt(0).toUpperCase() + props.step?.slice(1)}.vue`);
    stepComponent.value = module.default || module;
  } catch (error) {
    console.warn('Failed to load step component:', error);
  }
};

watch(() => props.step, loadComponent, { immediate: true });

const onEdit = (value: boolean) => {
  isDirty.value = value;
};

// Confirm Leave Dialog
const isDirty = ref<boolean>(false);

const confirmLeaveDialog = ref<boolean>(false);
const nextRoute = ref();

onBeforeRouteLeave((to, from, next) => {
  if (isDirty.value) {
    confirmLeaveDialog.value = true;
    nextRoute.value = to;
  } else {
    next();
  }
});

const onCancel = () => {
  confirmLeaveDialog.value = false;
  nextRoute.value = null;
};

const onConfirm = () => {
  confirmLeaveDialog.value = false;
  isDirty.value = false;
  router.push({ name: nextRoute.value.name });
  nextRoute.value = null;
};
</script>
