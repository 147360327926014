<template>
  <div class="top-0 z-50 w-full rounded-lg border bg-white p-5 shadow">
      <Menubar :model="menuItems">
        <template #item="{ item }">
          <router-link v-if="item.route" v-slot="{ href, navigate }" :to="{ name: item.route, params: { channel: item.channel } }" custom>
            <a :href="href" @click="navigate" :class="[isRouteActive(item.channel) ? 'text-primary-900' : 'text-primary-700', 'mr-2 flex items-center gap-1 rounded-md p-1 px-2 text-base']">
              <FontAwesomeIcon :icon="item.icon" size="sm"></FontAwesomeIcon>
              <span>{{ item.label }}</span>
            </a>
          </router-link>
        </template>
      </Menubar>
  </div>
  <RouterView />
</template>
<script setup lang="ts">
import { Menubar } from 'primevue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ContentChannelEnum } from '@/modules/aiContent/enums/ContentChannelEnum';
import { AI_CONTENT_ROUTES } from '@/modules/aiContent/enums/RoutesEnum';

const { t } = useI18n();
const route = useRoute();

const menuItems = [
  {
    label: t('socialMedia.socialMediaHeader'),
    channel: ContentChannelEnum.SocialMedia,
    icon: 'fa-table-columns',
    route: AI_CONTENT_ROUTES.SELECTION,
  },
  {
    label: t('socialMedia.blogHeader'),
    channel: ContentChannelEnum.Blog,
    icon: 'fa-table-columns',
    route: AI_CONTENT_ROUTES.SELECTION,
  },
  {
    label: t('socialMedia.emailHeader'),
    channel: ContentChannelEnum.Email,
    icon: 'fa-table-columns',
    route: AI_CONTENT_ROUTES.SELECTION,
  },
];

const isRouteActive = (channel: ContentChannelEnum) => {
  return (route.name === AI_CONTENT_ROUTES.SELECTION && route.params.channel === channel) || (route.name === AI_CONTENT_ROUTES.CREATE && route.params.channel === channel);
};
</script>
