import { RouteRecordRaw } from "vue-router";
import ClientLayout from "@/layout/ClientLayout.vue";
import { CONTACT_US_ROUTES } from "../enums/RoutesEnum";

const routes: Array<RouteRecordRaw> = [
    {
        path: 'contact',
        meta: {
            module: 'contactUs',
            layout: ClientLayout,
            quest: false, 
            client: true,
        },
        children: [
            {
                path: '',
                name: CONTACT_US_ROUTES.INDEX,
                component: () => import('@/modules/contactUs/pages/index.vue'),
            },
        ],
    },
];
export default routes