import { RouteRecordRaw } from 'vue-router';
import ClientLayout from '@/layout/ClientLayout.vue';
import { ESHOP_SETTINGS_ROUTES } from '../enums/RoutesEnum';

const routes: Array<RouteRecordRaw> = [
  {
    path: 'eshop-settings',
    meta: {
      module: 'eshopSettings',
      layout: ClientLayout,
      quest: false,
      client: true,
    },
    name:ESHOP_SETTINGS_ROUTES.INDEX,
    redirect: { name: ESHOP_SETTINGS_ROUTES.ESHOP_SETTINGS_PAGE},
    component: () => import('@/modules/eshopSettings/pages/index.vue'),
    children: [
      {
        path: 'eshop',
        name: ESHOP_SETTINGS_ROUTES.ESHOP_SETTINGS_PAGE,
        component: () => import('@/modules/eshopSettings/pages/EshopSettings.vue'),
      },
      {
        path:'notification',
        name: ESHOP_SETTINGS_ROUTES.NOTIFICATION_SETTINGS_PAGE,
        component: () => import('@/modules/eshopSettings/pages/NotificationSettings.vue')
      },
      {
        path: 'access',
        name: ESHOP_SETTINGS_ROUTES.ACCESS_SETTINGS_PAGE,
        component: () => import('@/modules/eshopSettings/pages/AccessSettings.vue')
      },
    ],
  },
];

export default routes;
