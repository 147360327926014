import { SharedEnumsLocale } from "../../generated/api";

export interface ILanguage {
  code: SharedEnumsLocale;
  text: string;
  flag: string;
}

const LanguagesCodebook: Array<ILanguage> = [
  {
    code: SharedEnumsLocale.Cs,
    text: "Česky",
    flag: "flags/CZ.png",
  },
  {
    code: SharedEnumsLocale.Da,
    text: "Dansk",
    flag: "flags/DK.png",
  },
  {
    code: SharedEnumsLocale.De,
    text: "Deutsch",
    flag: "flags/DE.png",
  },
  {
    code: SharedEnumsLocale.El,
    text: "Ελληνικά",
    flag: "flags/GR.png",
  },
  {
    code: SharedEnumsLocale.En,
    text: "English",
    flag: "flags/GB.png",
  },
  {
    code: SharedEnumsLocale.Es,
    text: "Español",
    flag: "flags/ES.png",
  },
  {
    code: SharedEnumsLocale.Fi,
    text: "Suomi",
    flag: "flags/FI.png",
  },
  {
    code: SharedEnumsLocale.Fr,
    text: "Français",
    flag: "flags/FR.png",
  },
  {
    code: SharedEnumsLocale.Hu,
    text: "Magyar",
    flag: "flags/HU.png",
  },
  {
    code: SharedEnumsLocale.It,
    text: "Italiano",
    flag: "flags/IT.png",
  },
  {
    code: SharedEnumsLocale.Nl,
    text: "Nederlands",
    flag: "flags/NL.png",
  },
  {
    code: SharedEnumsLocale.Nn,
    text: "Norsk",
    flag: "flags/NO.png",
  },
  {
    code: SharedEnumsLocale.Pl,
    text: "Polski",
    flag: "flags/PL.png",
  },
  {
    code: SharedEnumsLocale.Pt,
    text: "Português",
    flag: "flags/PT.png",
  },
  {
    code: SharedEnumsLocale.Ro,
    text: "Română",
    flag: "flags/RO.png",
  },
  {
    code: SharedEnumsLocale.Sk,
    text: "Slovensky",
    flag: "flags/SK.png",
  },
  {
    code: SharedEnumsLocale.Sv,
    text: "Svenska",
    flag: "flags/SE.png",
  },
];

export default LanguagesCodebook;
