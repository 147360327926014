import { RouteRecordRaw } from 'vue-router';
import FullPageLayout from '@/layout/FullPageLayout.vue';
import { ACCOUNT_ROUTES } from '@/modules/account/enums/RoutesEnum';
import { ACCOUNT_ROUTES_PATH } from '@/modules/account/enums/RoutesPathEnum';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    meta: {
      module: 'account',
      layout: FullPageLayout,
    },
    children: [
      {
        path: '',
        name: ACCOUNT_ROUTES.LOGIN,
        component: () => import('@/modules/account/pages/Login.vue'),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'register',
        name: ACCOUNT_ROUTES.REGISTER,
        component: () => import('@/modules/account/pages/Registration.vue'),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'login-as',
        name: ACCOUNT_ROUTES.LOGIN_AS,
        component: () => import('@/modules/account/pages/LoginAs.vue'),
        props: (route) => ({
          token: route.query.token,
        }),
        meta: {
          guest: true,
          client: true,
        },
      },
      {
        path: ':provider/callback',
        name: ACCOUNT_ROUTES.SOCIAL_AUTH_CALLBACK,
        component: () => import('@/modules/account/pages/SocialCallback.vue'),
        props: (route) => ({
          provider: route.params.provider,
        }),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: ACCOUNT_ROUTES_PATH.RESET_PASSWORD_CALLBACK,
        name: ACCOUNT_ROUTES.RESET_PASSWORD_CALLBACK,
        component: () => import('@/modules/account/pages/ResetPasswordCallback.vue'),
        props: (route) => ({
          token: route.query.token,
        }),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'forgotten-password',
        name: ACCOUNT_ROUTES.FORGOTTEN_PASSWORD,
        component: () => import('@/modules/account/pages/ForgottenPassword.vue'),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: ACCOUNT_ROUTES_PATH.CHANGE_EMAIL_CALLBACK,
        name: ACCOUNT_ROUTES.CHANGE_EMAIL_CALLBACK,
        component: () => import('@/modules/account/pages/ChangeEmailCallback.vue'),
        props: (route) => ({
          token: route.query.token,
        }),
        meta: {
          guest: true,
          client: true,
        },
      },
      {
        path: ACCOUNT_ROUTES_PATH.ADD_ESHOP_MANAGER_CALLBACK,
        name: ACCOUNT_ROUTES.ADD_ESHOP_MANAGER_CALLBACK,
        component: () => import('@/modules/account/pages/AddEshopManagerCallback.vue'),
        props: (route) => ({
          token: route.query.token,
        }),
        meta: {
          guest: true,
          client: false,
        },
      },
      {
        path: 'list-eshops',
        name: ACCOUNT_ROUTES.LIST_OF_ESHOPS,
        component: () => import('@/modules/account/pages/ListOfEshops.vue'),
        meta: {
          guest: false,
          client: true,
        },
      },
      {
        path: 'logout',
        name: ACCOUNT_ROUTES.LOGOUT,
        component: () => import('@/modules/account/pages/Logout.vue'),
        meta: {
          guest: false,
          client: true,
        },
      },
      {
        path: 'settings',
        name: ACCOUNT_ROUTES.SETTINGS,
        component: () => import('@/modules/account/pages/Settings.vue'),
        meta: {
          guest: false,
          client: true,
        },
      },
    ],
  },
];

export default routes;
