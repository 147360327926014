<template>
  <h1 class="my-6">{{ $t('socialMedia.' + type + 'Header') }}</h1>
  <SubscriptionBlock>AI tvorba obsahu je součástí předplatného <span class="font-semibold">Tanganica Premium.</span> Odemkněte možnosti, které vám pomohou růst.</SubscriptionBlock>
  <CardBlock>
    <form @submit.prevent="onSubmit" class="block p-4">
      <div v-for="section in formSchema.sections" :key="section.title" class="w-full space-y-4 lg:w-3/5">
        <h2>{{ section.title }}</h2>
        <div class="space-y-4">
          <component v-for="field in section.fields" :key="field.name" :is="field.input" :field="field" />
        </div>
      </div>
      <div class="ml-auto w-fit pt-5">
        <Button type="submit" severity="contrast" :label="$t('socialMedia.generate')"></Button>
      </div>
    </form>
  </CardBlock>
  <Dialog v-model:visible="showContentPreview" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header"></h1>
    </template>
    <div class="dialogStyle" v-html="contentPreview"></div>
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { useForm } from 'vee-validate';
import { markRaw, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import AutoComplete from '@/modules/aiContent/components/AutoComplete.vue';
import CheckBoxGroup from '@/modules/aiContent/components/CheckBoxGroup.vue';
import Select from '@/modules/aiContent/components/Select.vue';
import TextArea from '@/modules/aiContent/components/TextArea.vue';
import { ContentTypeEnum } from '@/modules/aiContent/enums/ContentTypeEnum';
import CardBlock from '@/modules/shared/components/CardBlock.vue';
import SubscriptionBlock from '@/modules/shared/components/SubscriptionBlock.vue';
import { usePremiumModal } from '@/modules/shared/composables/usePremiumModal';
import yup from '@/plugins/yup';
import { api } from '@/services/api';
import { handleError } from '@/utils/handleError';
import {
  SharedEnumsContentTargetAudienceSex,
  SharedEnumsContentTargetAudienceAgeGroup,
  SharedEnumsContentTargetAudienceType,
  SharedEnumsContentGoal,
  SharedEnumsContentCommunicationStyle,
  SharedEnumsSocialMedia,
  ClientDTOGenerateBlogContentIdeaRequest,
} from '../../../../generated/api';

type clientContentGenerateContentFormValues = Omit<ClientDTOGenerateBlogContentIdeaRequest, 'socialMedia'>; // TODO fix this

const props = defineProps({
  type: {
    type: String as PropType<ContentTypeEnum>,
    required: true,
  },
  socialMedia: {
    type: String as PropType<SharedEnumsSocialMedia>,
    required: false,
    default: '',
  },
});

const { t } = useI18n();

const createOptionsArrayFromEnum = (enumObject, translate: boolean = false) => {
  return Object.keys(enumObject).map((key) => {
    return {
      value: key,
      label: translate ? t('socialMedia.' + key) : key,
    };
  });
};

const targetAudienceSexes = createOptionsArrayFromEnum(SharedEnumsContentTargetAudienceSex);
const targetAudienceAgeGroups = createOptionsArrayFromEnum(SharedEnumsContentTargetAudienceAgeGroup);
const targetAudienceTypes = createOptionsArrayFromEnum(SharedEnumsContentTargetAudienceType);
const goals = createOptionsArrayFromEnum(SharedEnumsContentGoal, true);
const communicationStyles = createOptionsArrayFromEnum(SharedEnumsContentCommunicationStyle, true);

const targetAudienceSectionName = 'targetAudience'; // Need for the payload in the onSubmit function

const allFields = {
  targetAudienceSexes: {
    name: targetAudienceSectionName + 'Sexes',
    label: 'targetAudienceSexes',
    input: markRaw(CheckBoxGroup),
    defaultValue: [],
    options: targetAudienceSexes,
    rules: yup.array().of(yup.string()).min(1, 'Please select at least 1').required(),
  },
  targetAudienceAgeGroups: {
    name: targetAudienceSectionName + 'AgeGroups',
    label: 'targetAudienceAgeGroups',
    input: markRaw(CheckBoxGroup),
    defaultValue: [],
    options: targetAudienceAgeGroups,
    rules: yup.array().of(yup.string()).min(1, 'Please select at least 1').required(),
  },
  targetAudienceTypes: {
    name: targetAudienceSectionName + 'Types',
    label: 'targetAudienceTypes',
    input: markRaw(CheckBoxGroup),
    defaultValue: [],
    options: targetAudienceTypes,
    rules: yup.array().of(yup.string()).min(1, 'Please select at least 1').required(),
  },
  customTargets: {
    name: targetAudienceSectionName + 'CustomTargets',
    label: 'targetAudienceCustomTargets',
    defaultValue: [],
    input: markRaw(AutoComplete),
    rules: yup.array().of(yup.string()),
    placeholder: t('socialMedia.customTargetsPlaceholder'),
  },
  goals: {
    name: 'goals',
    label: 'goals',
    input: markRaw(Select),
    defaultValue: '',
    options: goals,
    rules: yup.object().required(() => t('shared.validations.required')),
    placeholder: t('socialMedia.chooseGoal'),
  },
  communicationStyles: {
    name: 'communicationStyles',
    label: 'communicationStyles',
    input: markRaw(Select),
    defaultValue: '',
    options: communicationStyles,
    rules: yup.object().required(() => t('shared.validations.required')),
    placeholder: t('socialMedia.chooseCommunicationStyleContent'),
  },
  contentDescription: {
    name: 'contentDescription',
    label: 'contentDescription',
    input: markRaw(TextArea),
    defaultValue: '',
    rules: yup.string().max(300, ''),
    placeholder: t('socialMedia.contentDescriptionPlaceholder'),
  },
  content: {
    name: 'content',
    label: 'content',
    input: markRaw(TextArea),
    defaultValue: '',
    rules: yup.string().required('validations.required'),
    placeholder: t('socialMedia.contentPlaceholder'),
  },
  details: {
    name: 'details',
    label: 'details',
    input: markRaw(TextArea),
    defaultValue: '',
    rules: yup.string().max(300, ''),
    placeholder: t('socialMedia.detailsPlaceholder'),
  },
};

const targetAudienceSection = {
  title: 'targetAudience',
  fields: [allFields.targetAudienceSexes, allFields.targetAudienceAgeGroups, allFields.customTargets],
};

const formSchemas = {
  [ContentTypeEnum.GenerateSocialMediaContentIdea]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.targetAudienceTypes, allFields.details],
      },
    ],
    submitMethod: (extraArgs: clientContentGenerateContentFormValues) =>
      api.clientContentGenerateSocialMediaContentIdea({
        socialMedia: props.socialMedia,
        ...extraArgs,
      }),
  },
  [ContentTypeEnum.GenerateSocialMediaContent]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.goals, allFields.communicationStyles, allFields.details],
      },
    ],
    submitMethod: (extraArgs: clientContentGenerateContentFormValues) =>
      api.clientContentGenerateSocialMediaContent({
        socialMedia: props.socialMedia,
        ...extraArgs,
      }),
  },
  [ContentTypeEnum.GenerateSocialMediaContentHeadline]: {
    sections: [
      {
        title: '',
        fields: [allFields.contentDescription, allFields.details],
      },
    ],
    submitMethod: (extraArgs: clientContentGenerateContentFormValues) =>
      api.clientContentGenerateSocialMediaContentHeadline({
        socialMedia: props.socialMedia,
        ...extraArgs,
      }),
  },
  [ContentTypeEnum.GenerateSocialMediaContentStrategy]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.targetAudienceTypes, allFields.goals, allFields.details],
      },
    ],
    submitMethod: (extraArgs: clientContentGenerateContentFormValues) =>
      api.clientContentGenerateSocialMediaContentStrategy({
        socialMedia: props.socialMedia,
        ...extraArgs,
      }),
  },
  [ContentTypeEnum.GenerateBlogContent]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.communicationStyles, allFields.contentDescription, allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateBlogContent,
  },
  [ContentTypeEnum.GenerateBlogContentStrategy]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.targetAudienceTypes, allFields.goals, allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateBlogContentStrategy,
  },
  [ContentTypeEnum.GenerateBlogContentIdea]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.targetAudienceTypes, allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateBlogContentIdea,
  },
  [ContentTypeEnum.OptimizeBlogContent]: {
    sections: [
      {
        title: '',
        fields: [allFields.content, allFields.details],
      },
    ],
    submitMethod: api.clientContentOptimizeBlogContent,
  },
  [ContentTypeEnum.GenerateNewsletterIdea]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateNewsletterIdea,
  },
  [ContentTypeEnum.GenerateNewsletter]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.communicationStyles, allFields.contentDescription, allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateNewsletter,
  },
  [ContentTypeEnum.GenerateEmailContentStrategy]: {
    sections: [
      targetAudienceSection,
      {
        title: '',
        fields: [allFields.goals, allFields.details],
      },
    ],
    submitMethod: api.clientContentGenerateEmailContentStrategy,
  },
};

const formSchema = ref(formSchemas[props.type]);

watch(
  () => props.type,
  () => {
    formSchema.value = formSchemas[props.type];
  }
);

const { handleSubmit } = useForm();
const { open: openPremiumModal } = usePremiumModal();

const showContentPreview = ref(false);
const contentPreview = ref();

const onSubmit = handleSubmit(async (formData) => {
  // TODO TESTING PREMIUM MODAL
  openPremiumModal();
  return;

  const payload = {};

  Object.keys(formData).forEach((key) => {
    if (key.startsWith(targetAudienceSectionName)) {
      const removeTargetAudienceSectionName = key.replace(targetAudienceSectionName, '');
      const newKey = removeTargetAudienceSectionName.charAt(0).toLowerCase() + removeTargetAudienceSectionName.slice(1);

      payload[targetAudienceSectionName] = {
        ...payload[targetAudienceSectionName],
        [newKey]: formData[key],
      };
    } else {
      payload[key] = formData[key];
    }
  });

  // TODO - remove this
  console.log('payload', payload);

  return;
  try {
    const { data: eshopDescription } = await api.clientEshopSettingsGetEshopDescription(); // TODO - why is this here?

    if (!eshopDescription.description) {
      await api.clientEshopSettingsCreateEshopDescription();
    }

    const { data } = await formSchema.value.submitMethod(payload);
    console.log('data', data);
  } catch (error: any) {
    handleError(error);
  }
});
</script>
<style>
div.dialogStyle p {
  margin-top: 2rem !important;
}

.dialogStyle h2 {
  margin-top: 2rem !important;
  font-size: 2rem !important;
  font-weight: 800 !important;
}
</style>
